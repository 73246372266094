<template>
    <div>
      <h2>Add Leads to CRM</h2>
      <p>This page allows you to add leads to CRM.</p>
      <!-- Add any additional content or form here -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'DriverReporting',
  };
  </script>
  
  <style scoped>
  /* Add styles for Check Personalizations here */
  </style>
  