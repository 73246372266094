import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        loggedIn: false,
        user: null,
    }),
    actions: {
        login(userData) {
            this.loggedIn = true;
            this.user = userData;
            localStorage.setItem('loggedIn', 'true')
        },
        logout() {
            this.loggedIn = false;
            this.user = null;
            localStorage.removeItem('loggedIn')
        },
        checkAuth() {
            this.loggedIn = localStorage.getItem('loggedIn') === 'true';
        }
    }
});