<template>
  <div class="scene-container">
    <h2>Select a Project to View Scenes</h2>

    <!-- Project Selection Dropdown -->
    <div class="project-selector">
      <label for="projectSelect">Project:</label>
      <select id="projectSelect" v-model="selectedProjectId" @change="onProjectChange">
        <option disabled value="">Please select a project</option>
        <option v-for="project in projects" :key="project.id" :value="project.id">
          {{ project.name }}
        </option>
      </select>
    </div>

    <!-- Loading Spinner -->
    <div v-if="loading" class="loading-spinner"></div>

    <!-- Main Content Section with Scenes and Details Side by Side -->
    <div v-if="scenes.length > 0 && !loading" class="main-content">
      <!-- Scene List -->
      <div class="scene-list">
        <h3>Scenes</h3>
        <ul>
          <li
            v-for="(scene, index) in scenes"
            :key="index"
            @click="selectScene(scene)"
            :class="{ 'selected': scene === selectedScene }"
          >
            <strong>Scene {{ scene.scene_nr }}:</strong> {{ scene.synopsis }}
          </li>
        </ul>
        <!-- Confirm Scenes Button -->
        <button
          class="confirm-btn"
          :disabled="!allDurationsSet"
          @click="confirmScenes"
        >
          Confirm Scenes
        </button>
      </div>

      <!-- Scene Detail View -->
      <div v-if="selectedScene" class="scene-detail">
        <div class="scene-detail-header">
          <h3>Scene Details for Scene {{ selectedScene.scene_nr }}</h3>
          <button @click="toggleEditMode" class="edit-btn">
            {{ isEditing ? 'Cancel' : 'Edit' }}
          </button>
        </div>
        <table>
          <tr>
            <td><strong>Location Type:</strong></td>
            <td>
              <span v-if="!isEditing">{{ selectedScene.location_type || 'N/A' }}</span>
              <input v-else v-model="selectedScene.location_type" />
            </td>
          </tr>
          <tr>
            <td><strong>Time of Day:</strong></td>
            <td>
              <span v-if="!isEditing">{{ selectedScene.time_of_day || 'N/A' }}</span>
              <input v-else v-model="selectedScene.time_of_day" />
            </td>
          </tr>
          <tr>
            <td><strong>Synopsis:</strong></td>
            <td>
              <span v-if="!isEditing">{{ selectedScene.synopsis || 'N/A' }}</span>
              <textarea v-else v-model="selectedScene.synopsis"></textarea>
            </td>
          </tr>
          <tr>
            <td><strong>Location:</strong></td>
            <td>
              <span v-if="!isEditing">{{ selectedScene.location || 'N/A' }}</span>
              <input v-else v-model="selectedScene.location" />
            </td>
          </tr>
          <tr>
            <td><strong>Actors:</strong></td>
            <td>
              <div v-if="!isEditing">
                {{ (selectedScene.actors || []).join(', ') || 'N/A' }}
              </div>
              <div v-else class="tag-input">
                <div class="tags">
                  <span v-for="(actor, idx) in selectedScene.actors || []" :key="idx" class="tag">
                    {{ actor }}
                    <button @click="removeItem('actors', idx)" class="remove-btn">×</button>
                  </span>
                </div>
                <input v-model="newActor" placeholder="Add new actor" @keyup.enter="addItem('actors')" />
                <button @click="addItem('actors')" class="add-btn">Add</button>
              </div>
            </td>
          </tr>
          <tr>
            <td><strong>Props:</strong></td>
            <td>
              <div v-if="!isEditing">
                {{ (selectedScene.props || []).join(', ') || 'N/A' }}
              </div>
              <div v-else class="tag-input">
                <div class="tags">
                  <span v-for="(prop, idx) in selectedScene.props || []" :key="idx" class="tag">
                    {{ prop }}
                    <button @click="removeItem('props', idx)" class="remove-btn">×</button>
                  </span>
                </div>
                <input v-model="newProp" placeholder="Add new prop" @keyup.enter="addItem('props')" />
                <button @click="addItem('props')" class="add-btn">Add</button>
              </div>
            </td>
          </tr>
          <tr>
            <td><strong>Specials:</strong></td>
            <td>
              <div v-if="!isEditing">
                {{ (selectedScene.specials || []).join(', ') || 'N/A' }}
              </div>
              <div v-else class="tag-input">
                <div class="tags">
                  <span v-for="(special, idx) in selectedScene.specials || []" :key="idx" class="tag">
                    {{ special }}
                    <button @click="removeItem('specials', idx)" class="remove-btn">×</button>
                  </span>
                </div>
                <input v-model="newSpecial" placeholder="Add new special" @keyup.enter="addItem('specials')" />
                <button @click="addItem('specials')" class="add-btn">Add</button>
              </div>
            </td>
          </tr>
          <tr>
            <td><strong>Duration:</strong></td>
            <td>
              <span v-if="!isEditing">
                {{ selectedScene.duration || 'N/A' }}
                <small>(in minutes)</small>
              </span>
              <select v-else v-model="selectedScene.duration" @change="updateDuration">
                <option
                  v-for="minutes in durationOptions"
                  :key="minutes"
                  :value="minutes">
                  {{ formatDuration(minutes) }}
                </option>
              </select>
            </td>
          </tr>
        </table>
        <button v-if="isEditing" @click="saveSceneDetails" class="save-btn">Save</button>
      </div>
    </div>

    <!-- Actors Availability Section -->
    <div v-if="showActorsSection" class="main-content">
      <!-- Actors List -->
      <div class="scene-list">
        <h3>Actors</h3>
        <ul>
          <li
            v-for="(actor, index) in actors"
            :key="index"
            @click="selectActor(actor)"
            :class="{ 'selected': actor === selectedActor }"
          >
            {{ actor.name }}
          </li>
        </ul>
      </div>

      <!-- Actor Availability Detail View -->
      <div v-if="selectedActor" class="scene-detail">
        <div class="scene-detail-header">
          <h3>Availability for {{ selectedActor.name }}</h3>
        </div>
        <div class="actor-details">
          <label for="actorName">Actor Name:</label>
          <input id="actorName" v-model="selectedActor.actorName" />
        </div>
        <div class="availability-periods">
          <h4>Add Unavailability Periods</h4>
          <div
            v-for="(period, index) in selectedActor.unavailablePeriods"
            :key="index"
            class="unavailability-period"
          >
            <vue-datepicker
              v-model="period.start"
              :type="'datetime'"
              :placeholder="'Start Date and Time'"
            ></vue-datepicker>
            <vue-datepicker
              v-model="period.end"
              :type="'datetime'"
              :placeholder="'End Date and Time'"
            ></vue-datepicker>
            <button @click="removeUnavailabilityPeriod('actor', index)" class="remove-btn">Remove</button>
          </div>
          <button @click="addUnavailabilityPeriod('actor')" class="add-btn">Add Unavailability Period</button>
        </div>
        <button @click="saveActorAvailability" class="save-btn">Save Availability</button>
      </div>
    </div>

    <!-- Locations Availability Section -->
    <div v-if="showLocationsSection" class="main-content">
      <!-- Locations List -->
      <div class="scene-list">
        <h3>Locations</h3>
        <ul>
          <li
            v-for="(location, index) in locations"
            :key="index"
            @click="selectLocation(location)"
            :class="{ 'selected': location === selectedLocation }"
          >
            {{ location.name }}
          </li>
        </ul>
      </div>

      <!-- Location Availability Detail View -->
      <div v-if="selectedLocation" class="scene-detail">
        <div class="scene-detail-header">
          <h3>Availability for {{ selectedLocation.name }}</h3>
        </div>
        <div class="availability-periods">
          <h4>Add Unavailability Periods</h4>
          <div
            v-for="(period, index) in selectedLocation.unavailablePeriods"
            :key="index"
            class="unavailability-period"
          >
            <vue-datepicker
              v-model="period.start"
              :type="'datetime'"
              :placeholder="'Start Date and Time'"
            ></vue-datepicker>
            <vue-datepicker
              v-model="period.end"
              :type="'datetime'"
              :placeholder="'End Date and Time'"
            ></vue-datepicker>
            <button @click="removeUnavailabilityPeriod('location', index)" class="remove-btn">Remove</button>
          </div>
          <button @click="addUnavailabilityPeriod('location')" class="add-btn">Add Unavailability Period</button>
        </div>
        <button @click="saveLocationAvailability" class="save-btn">Save Availability</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: {
    VueDatepicker,
  },
  data() {
    return {
      projects: [],
      selectedProjectId: '',
      scenes: [],
      selectedScene: null,
      isEditing: false,
      newActor: '',
      newProp: '',
      newSpecial: '',
      loading: false,
      errorMessage: '',
      // New data properties
      actors: [],
      selectedActor: null,
      showActorsSection: false,
      locations: [],
      selectedLocation: null,
      showLocationsSection: false,
      durationOptions: this.generateDurationOptions(),
    };
  },
  computed: {
    allDurationsSet() {
      return this.scenes.every(scene => scene.duration && scene.duration !== 'N/A');
    },
  },
  created() {
    this.loadProjects();
  },
  methods: {
    generateDurationOptions() {
      const options = [];
      for (let i = 15; i <= 360; i += 15) {
        options.push(i);
      }
      return options;
    },
    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours > 0 ? hours + 'h ' : ''}${mins > 0 ? mins + 'm' : ''}`.trim();
    },
    updateDuration() {
      this.selectedScene.duration = parseInt(this.selectedScene.duration, 10);
    },
    async loadProjects() {
      try {
        const response = await fetch('https://api.fastwrap.io/projects', {
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error('Failed to load projects');
        }
        const data = await response.json();
        this.projects = data;
      } catch (error) {
        console.error('Error loading projects:', error);
        this.errorMessage = error.message || 'Failed to load projects.';
      }
    },
    async onProjectChange() {
      if (this.selectedProjectId) {
        this.loading = true;
        try {
          const response = await fetch(
            `https://api.fastwrap.io/projects/${this.selectedProjectId}/retrieve-scenes`,
            {
              credentials: 'include',
            }
          );
          if (!response.ok) {
            throw new Error('Failed to load scenes');
          }
          const data = await response.json();
          this.scenes = data;
          this.selectedScene = null;
          // After loading scenes, extract actors and locations
          if (this.scenes.length > 0) {
            this.extractActorsAndLocations();
          }
        } catch (error) {
          console.error('Error loading scenes:', error);
          this.errorMessage = error.message || 'Failed to load scenes.';
        } finally {
          this.loading = false;
        }
      } else {
        this.scenes = [];
        this.selectedScene = null;
      }
    },
    extractActorsAndLocations() {
      const actorMap = new Map();
      const locationSet = new Set();
      this.scenes.forEach(scene => {
        (scene.actors || []).forEach(roleName => {
          if (!actorMap.has(roleName)) {
            actorMap.set(roleName, {
              name: roleName, // role name
              actorName: '', // actual actor's name
              unavailablePeriods: [],
            });
          }
        });
        if (scene.location) {
          locationSet.add(scene.location);
        }
      });
      this.actors = Array.from(actorMap.values());
      this.locations = Array.from(locationSet).map(name => ({
        name,
        unavailablePeriods: [],
      }));
    },
    selectScene(scene) {
      this.selectedScene = { ...scene }; // Create a copy to avoid mutating the original
      this.isEditing = false;
    },
    toggleEditMode() {
      this.isEditing = !this.isEditing;
    },
    addItem(field) {
      if (field === 'actors' && this.newActor) {
        if (!Array.isArray(this.selectedScene.actors)) {
          this.selectedScene.actors = [];
        }
        this.selectedScene.actors.push(this.newActor);
        this.newActor = '';
      } else if (field === 'props' && this.newProp) {
        if (!Array.isArray(this.selectedScene.props)) {
          this.selectedScene.props = [];
        }
        this.selectedScene.props.push(this.newProp);
        this.newProp = '';
      } else if (field === 'specials' && this.newSpecial) {
        if (!Array.isArray(this.selectedScene.specials)) {
          this.selectedScene.specials = [];
        }
        this.selectedScene.specials.push(this.newSpecial);
        this.newSpecial = '';
      }
    },
    removeItem(field, index) {
      if (Array.isArray(this.selectedScene[field])) {
        this.selectedScene[field].splice(index, 1);
      }
    },
    async saveSceneDetails() {
      try {
        const response = await fetch(
          `https://api.fastwrap.io/projects/${this.selectedProjectId}/scenes/${this.selectedScene.id}`,
          {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.selectedScene),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to save scene details');
        }
        // Update the scene in the scenes array
        const index = this.scenes.findIndex(scene => scene.id === this.selectedScene.id);
        if (index !== -1) {
          this.scenes.splice(index, 1, { ...this.selectedScene });
        }
        this.isEditing = false;
      } catch (error) {
        console.error('Error saving scene details:', error);
        this.errorMessage = error.message || 'Failed to save scene details.';
      }
    },
    confirmScenes() {
      this.showActorsSection = true;
      this.showLocationsSection = true;
    },
    selectActor(actor) {
      this.selectedActor = actor;
      this.loadActorAvailability(actor.roleName)
    },
    addUnavailabilityPeriod(type) {
      if (type === 'actor') {
        this.selectedActor.unavailablePeriods.push({ start: null, end: null });
      } else if (type === 'location') {
        this.selectedLocation.unavailablePeriods.push({ start: null, end: null });
      }
    },
    removeUnavailabilityPeriod(type, index) {
      if (type === 'actor') {
        this.selectedActor.unavailablePeriods.splice(index, 1);
      } else if (type === 'location') {
        this.selectedLocation.unavailablePeriods.splice(index, 1);
      }
    },
    async loadActorAvailability(roleName) {
      try {
        const response = await fetch(`https://api.fastwrap.io/projects/${encodeURIComponent(this.selectedProjectId)}/actors/${encodeURIComponent(this.selectedActor.name)}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to load actor availability');
        }

        const data = await response.json();
        const actor = this.actors.find(actor => actor.name === roleName);

        if (actor) {
          actor.actorName = data.actorName;

          actor.unavailablePeriods = data.unavailablePeriods.map(period => ({
            start: period.start,
            end: period.end,
          }));
        }
      } catch (error) {
        console.error('Error loading actor availability:', error);
        this.errorMessage = error.message || 'Failed to load actor availability.';
      }
    },
    async saveActorAvailability() {
      try {
        const unavailablePeriods = Array.isArray(this.selectedActor.unavailablePeriods)
        ? this.selectedActor.unavailablePeriods.map(period => {
            // Check if `period` is an array (unexpected nested format)
            if (Array.isArray(period)) {
              // Convert nested array format to a flat object with start and end
              const startEntry = period.find(item => item[0] === "start");
              const endEntry = period.find(item => item[0] === "end");

              return {
                start: startEntry ? new Date(startEntry[1]).toISOString() : null,
                end: endEntry ? new Date(endEntry[1]).toISOString() : null,
              };
            } else {
              // Assume it's already in the correct object format
              return {
                start: period.start ? new Date(period.start).toISOString() : null,
                end: period.end ? new Date(period.end).toISOString() : null,
              };
            }
          })
        : [];

        const response = await fetch(
          `https://api.fastwrap.io/actors/${encodeURIComponent(this.selectedActor.name)}/availability`,
          {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              actor_name: this.selectedActor.actorName,
              role_name: this.selectedActor.name,       
              unavailable_periods: unavailablePeriods,  
              project_id: this.selectedProjectId,       
            }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to save actor availability');
        }
        alert(`Saved availability for ${this.selectedActor.name}`);
      } catch (error) {
        console.error('Error saving actor availability:', error);
        this.errorMessage = error.message || 'Failed to save actor availability.';
      }
    },
    selectLocation(location) {
      this.selectedLocation = location;
      if (!Array.isArray(this.selectedLocation.unavailablePeriods)) {
        this.selectedLocation.unavailablePeriods = [];
      }
    },
    async saveLocationAvailability() {
      try {
        const unavailablePeriods = Array.isArray(this.selectedLocation.unavailablePeriods)
          ? this.selectedLocation.unavailablePeriods.map(period => ({
              start: period.start ? new Date(period.start).toISOString() : null,
              end: period.end ? new Date(period.end).toISOString() : null,
            }))
          : [];

        const response = await fetch(
          `https://api.fastwrap.io/locations/${encodeURIComponent(this.selectedLocation.name)}/availability`,
          {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              location_name: this.selectedLocation.name,
              unavailable_periods: unavailablePeriods,   
              project_id: this.selectedProjectId,        
            }),
          }
        );
        if (!response.ok) {
          throw new Error('Failed to save location availability');
        }
        alert(`Saved availability for ${this.selectedLocation.name}`);
      } catch (error) {
        console.error('Error saving location availability:', error);
        this.errorMessage = error.message || 'Failed to save location availability.';
      }
    },
  },
};
</script>

<style scoped>
.scene-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 10px;
}

.project-selector {
  margin-bottom: 20px;
}

.project-selector select {
  padding: 5px;
  border-radius: 5px;
}

.edit-btn,
.save-btn {
  background-color: #42b983;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn {
  margin-top: 10px;
}

.loading-spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #42b983;
  animation: spin 1s ease-in-out infinite;
  margin-left: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.tag-input {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tag {
  background-color: #e0f7e9;
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.remove-btn {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  margin-left: 5px;
  font-weight: bold;
}

.add-btn {
  background-color: #42b983;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn {
  background-color: #42b983;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.main-content {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.scene-list {
  width: 40%;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #ddd;
  overflow-y: auto;
  max-height: 500px;
}

.scene-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.scene-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.scene-list li:hover {
  background-color: #e6f7f1;
}

.scene-list li.selected {
  background-color: #42b983;
  color: white;
}

.scene-detail {
  width: 60%;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
}

table {
  width: 100%;
}

td {
  padding: 8px;
  vertical-align: top;
}

.confirm-btn {
  margin-top: 10px;
  background-color: #42b983;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.confirm-btn:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.availability-calendar,
.availability-periods {
  margin-top: 20px;
}

.unavailability-period {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.unavailability-period .vue-datepicker {
  flex: 1;
}

.add-btn,
.remove-btn {
  background-color: #42b983;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.remove-btn {
  background-color: #e74c3c;
}

.actor-details {
  margin-bottom: 20px;
}

.actor-details label {
  display: block;
  margin-bottom: 5px;
}

.actor-details input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
</style>
