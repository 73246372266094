<template>
  <div class="login-page">
    <div class="top-bar">
      <div class="logo">
        <div class="logo-circle">
        </div>
        <span>FastWrap</span>
      </div>
      <div class="language-selector">
        <span class="globe-icon">🌐</span>
        <span>English</span>
        <span class="arrow-down">▼</span>
      </div>
    </div>

    <div class="content">
      <div class="login-container">
        <h1>Think it. Make it.</h1>
        <h2>Log in to your FastWrap account</h2>
        
        <form @submit.prevent="handleLogin">
          <div class="form-group">
            <label for="email">Email</label>
            <div class="input-wrapper">
              <span class="input-icon">✉️</span>
              <input 
                type="email" 
                id="email" 
                v-model="email" 
                placeholder="Enter your email address"
                required 
              />
            </div>
          </div>
          
          <div class="form-group">
            <label for="password">Password</label>
            <div class="input-wrapper">
              <span class="input-icon">🔒</span>
              <input 
                type="password" 
                id="password" 
                v-model="password" 
                placeholder="Enter your password"
                required 
              />
            </div>
          </div>
    
          <button type="submit" class="submit-button">Continue</button>

          <p class="terms-text">
            By continuing, you acknowledge that you understand and agree FastWrap's 
            <a href="#">Terms & Conditions</a> and <a href="#">Privacy Policy</a>.
          </p>
        </form>
      </div>
    </div>

    <footer>
      <p>Copyright © 2024 FastWrap. All rights reserved.</p>
      <nav>
        <a href="#">Cookie Preferences</a>
        <a href="#">Contact</a>
      </nav>
    </footer>
  </div>
</template>

<script>
import { useAuthStore } from '../stores/auth';

export default {
  name: 'UserLogin',
  data() {
    return {
      email: '',
      password: ''
    };
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  methods: {
    async handleLogin() {
      try {
        const response = await fetch('https://api.fastwrap.io/user/v1/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            email: this.email,
            password: this.password
          }),
        });

        const data = await response.json();

        if (data.email) {
          this.authStore.login({ email: this.email });
          this.$router.push('/dashboard');
        } else {
          alert('Login failed, please try again.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred during login.');
      }
    }
  }
};
</script>

<style scoped>
.login-page {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  margin-left: 0;
}

.logo-circle {
  height: 50px;
  width: 50px;
  background: #d9d9d9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  height: 30px;
  width: 30px;
}

.language-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #91908f;
  font-size: 0.9rem;
  cursor: pointer;
}

.arrow-down {
  font-size: 0.7rem;
  margin-left: 0.5rem;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.login-container {
  width: 100%;
  max-width: 450px;
  padding: 2rem;
  background: white;
  border-radius: 1.5rem;
  margin: 0;
}

h1 {
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h2 {
  color: #91908f;
  font-size: 1.2rem;
  margin-bottom: 3rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #91908f;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: #d9d9d9;
  border-radius: 5px;
}

.input-icon {
  position: absolute;
  left: 1rem;
  color: #666;
}

input {
  width: 100%;
  padding: 0.8rem 1rem 0.8rem 2.5rem;
  background: transparent;
  border: none;
  border-radius: 0.5rem;
}

input::placeholder {
  color: #91908f;
}

.submit-button {
  width: 100%;
  padding: 0.8rem;
  background: #3E83DB;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin: 2rem 0;
}

.terms-text {
  font-size: 0.9rem;
  color: #666;
  text-align: center;
}

.terms-text a {
  color: #4285f4;
  text-decoration: none;
}

footer {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 0.9rem;
  margin-top: auto;
}

footer nav a {
  color: white;
  text-decoration: none;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .login-container {
    margin: 1rem;
    padding: 1.5rem;
  }

  footer {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  footer nav a {
    display: block;
    margin: 0.5rem 0;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1rem;
  }
}
</style>
  