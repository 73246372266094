<template>
  <div id="app">
    <!-- Display the route-specific component -->
    <router-view />
  </div>
</template>

<script>
  import { useAuthStore } from './stores/auth';

export default {
  name: 'App',
  setup() {
    const authStore = useAuthStore();
    authStore.checkAuth();

    return { authStore };
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
nav {
  margin-bottom: 20px;
}

router-link {
  text-decoration: none;
  color: #42b983;
}
</style>
