import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import './assets/main.css'

import AddRoutes from './components/AddRoutes.vue'
import UserLogin from './components/UserLogin.vue'
import UserDashboard from './components/UserDashboard.vue'
import ClientFulfillment from './components/ClientFulfillment.vue'
import DriverReporting from './components/DriverReporting.vue'
import { useAuthStore } from './stores/auth'

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login', 
        component: UserLogin},
    {
        path: '/dashboard', 
        component: UserDashboard,
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.loggedIn) {
                next();
            } else {
                next('/login');
            }
        },
        children: [
            {
              path: 'scenes',
              name: 'Scenes',
              component: ClientFulfillment,
            },
            {
                path: 'shooting-schedule',
                name: 'Shooting Schedule',
                component: AddRoutes,
              },
            {
              path: 'reporting',
              name: 'Placeholder',
              component: DriverReporting,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App);
app.use(createPinia())
app.use(router);
app.mount('#app');
