<template>
  <div class="dashboard-container">
    <div class="sidebar">
      <nav>
        <ul>
          <li>
            <router-link to="/dashboard" exact-active-class="router-link-exact-active">
              Dashboard
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/scenes" exact-active-class="router-link-exact-active">
              Scenes
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/shooting-schedule" exact-active-class="router-link-exact-active">
              Shooting Schedule
            </router-link>
          </li>
          <li>
            <router-link to="/dashboard/reporting" exact-active-class="router-link-exact-active">
              Placeholder
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="sidebar-footer">
        <button @click="handleLogout">Logout</button>
      </div>
    </div>

    <div class="content">
  <!-- Render the main dashboard content only when the exact path is '/dashboard' -->
  <div v-if="$route.path === '/dashboard'">
    <h1>Your Projects</h1>
    <div class="section">
      <h2>Existing Projects</h2>
      <div class="projects-grid">
        <div class="project-card" v-for="project in projects" :key="project.id">
          <h3>{{ project.name }}</h3>

          <!-- Display screenplay filename if it exists -->
          <div v-if="project.has_screenplay">
            <p>Screenplay: {{ project.screenplay_filename }}</p>
            <button class="primary-button" disabled>Upload New Version (Coming Soon)</button>
          </div>
          <!-- Else, show the upload screenplay option -->
          <div v-else>
            <input
              type="file"
              @change="handleFileUpload($event, project.id)"
              :ref="'fileInput-' + project.id"
            />
            <button @click="uploadScreenplay(project.id)">Upload Screenplay</button>
          </div>

          <!-- New Buttons -->
          <button
            class="primary-button"
            @click="generateScenes(project.id)"
            :disabled="!project.has_screenplay"
            :class="{ 'button-disabled': !project.has_screenplay }"
          >
            Generate Scenes
          </button>

          <button
            class="primary-button"
            @click="generateShootingSchedule(project.id)"
            :class="{ 'button-disabled': !project.has_scenes }"
          >
            Generate Shooting Schedule
          </button>

          <button
            class="primary-button"
            @click="downloadCallSheets(project.id)"
            :disabled="!project.has_shooting_schedule"
          >
            Download Call Sheets
          </button>

          <!-- Remove the Select Project button -->
          <!-- <button @click="selectProject(project.id)">Select Project</button> -->

          <button @click="deleteProject(project.id)" class="delete-btn">
            Delete Project
          </button>
        </div>
      </div>
    </div>

    <div class="section create-project">
      <h2>Create Project</h2>
      <input
        type="text"
        v-model="newProjectName"
        placeholder="Project Name"
      />
      <button :disabled="!newProjectName" @click="addProject">Add Project</button>
    </div>
  </div>
  <!-- Display different content based on the route -->
  <router-view v-else></router-view>
</div>
  </div>
</template>

<script>
import { useAuthStore } from '../stores/auth'; // Import the auth store

export default {
  name: 'UserDashboard',
  data() {
    return {
      newProjectName: '',
      projects: [],
      fileToUpload: null,
      errorMessage: '',
    };
  },
  async created() {
    await this.loadProjects();
  },
  methods: {
    handleLogout() {
      this.authStore.logout(); // Clear the login state
      this.$router.push('/login'); // Redirect to login page
    },
    async loadProjects() {
      // Load projects from the backend
      const response = await fetch('https://api.fastwrap.io/projects', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
      // Handle HTTP errors
      console.error('Failed to load projects:', response.statusText);
      return;
      }

      const data = await response.json();
      this.projects = data;
    },
    async addProject() {
      // Add new project
      const response = await fetch('https://api.fastwrap.io/projects', {
        method: 'POST',
        credentials: 'include', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: this.newProjectName }),
      });

      if (!response.ok) {
      throw new Error(`Failed to create project: ${response.statusText}`);
    }

      const data = await response.json();
      this.projects.push(data.project);

      this.projects.push({
      id: data.project_id,
      name: this.newProjectName,
      has_screenplay: false,
      has_scenes: false,
      has_shooting_schedule: false,
    });

      await this.loadProjects();

      this.newProjectName = '';
    },
    async deleteProject(projectId) {
      // Delete project by ID
      await fetch(`https://api.fastwrap.io/projects/${projectId}`, {
        method: 'DELETE',
      });
      this.projects = this.projects.filter((project) => project._id !== projectId);
    },
    handleFileUpload(event, projectId) {
      const file = event.target.files[0];
      if (file) {
        this.fileToUpload = { projectId, file };
      }
    },
    async uploadScreenplay(projectId) {
      if (!this.fileToUpload || this.fileToUpload.projectId !== projectId) return;

      const formData = new FormData();
      formData.append('file', this.fileToUpload.file);

      try {
        const response = await fetch(`https://api.fastwrap.io/projects/${projectId}/screenplays`, {
          method: 'POST',
          credentials: 'include', // Include credentials if authentication is required
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Optionally handle the response
        const data = await response.json();
        console.log('Screenplay uploaded:', data);
        
        await this.loadProjects();
        this.fileToUpload = null;
      } 
    catch (error) {
      console.error('Error uploading screenplay:', error);
      // Optionally, display an error message to the user
      this.errorMessage = 'Failed to upload screenplay. Please try again.';
      }
    },


    selectProject(projectId) {
      this.$router.push({ name: 'projectDetail', params: { id: projectId } });
    },

    async generateScenes(projectId) {
    try {
      const response = await fetch(`https://api.fastwrap.io/projects/${projectId}/create-scenes`, {
        method: 'POST',
        credentials: 'include', // Include credentials if authentication is required
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        // Handle HTTP errors
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to generate scenes');
      }

      const data = await response.json();
      console.log('Scenes generated successfully:', data);

      // Update the project state to reflect that scenes have been generated
      const project = this.projects.find(p => p.id === projectId);
      if (project) {
        project.has_scenes = true;
      }
      await this.loadProjects();
      // Optionally, provide user feedback
      alert('Scenes generated successfully.');
    } catch (error) {
      console.error('Error generating scenes:', error);
      // Optionally, display an error message to the user
      this.errorMessage = error.message || 'Failed to generate scenes. Please try again.';
    }
  },

  async generateShootingSchedule(projectId) {
      try {
        const response = await fetch(`https://api.fastwrap.io/projects/${projectId}/create-schedule`, {
          method: 'POST', // Assuming this endpoint is a POST request; adjust to 'GET' if needed
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include' // Include credentials if your API requires authentication
        });

        if (!response.ok) {
          throw new Error(`Failed to generate schedule: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Schedule generated:', data);

        // You can further handle the response here, e.g., display a success message or update the UI
      } catch (error) {
        console.error('Error generating schedule:', error);
        // Optionally, show an error message to the user
      }
    }

  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  }
};
</script>

<style scoped>
/* Main layout */
.dashboard-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

/* Sidebar styling */
.sidebar {
  width: 250px;
  background-color: #f5f5f5;
  padding: 20px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.sidebar ul li a.router-link-exact-active {
  color: #42b983;
}

.sidebar-footer button {
  display: block;
  width: 100%;
  background-color: #42b983;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-footer button:hover {
  background-color: #358960;
}

/* Content styling */
.content {
  flex-grow: 1;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.project-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  background-color: #fff;
}

.project-card h3 {
  margin-top: 0;
}

.delete-btn {
  background-color: #e74c3c;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.delete-btn:hover {
  background-color: #c0392b;
}

.create-project {
  display: flex;
  align-items: center;
  gap: 10px;
}

.primary-button {
  background-color: #42b983;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}
.primary-button:hover {
  background-color: #358960;
}

.primary-button:disabled {
  background-color: #888;
  cursor: not-allowed;
}

</style>
