<template>
  <div class="schedule-container">
    <h1>Shooting Schedule</h1>

    <!-- Project Selection Dropdown -->
    <div class="project-selector">
      <label for="projectSelect">Select Project:</label>
      <select id="projectSelect" v-model="selectedProjectId" @change="fetchSchedule">
        <option disabled value="">Please select a project</option>
        <option v-for="project in projects" :key="project.id" :value="project.id">
          {{ project.name }}
        </option>
      </select>
    </div>

    <!-- Schedule Display -->
    <div v-if="scheduleGroupedByDay.length > 0" class="schedule">
      <div v-for="(day, index) in scheduleGroupedByDay" :key="index" class="day-schedule">
        <div class="schedule-header">
          <span>Day {{ day.dayNumber }}</span>
          <span>Daylight: {{ daylightTime }}</span>
        </div>
        <div class="schedule-list">
          <div
            v-for="(sequence, index) in day.sequences"
            :key="sequence._id"
            class="sequence"
          >
            <div class="sequence-header" :style="{ backgroundColor: getBackgroundColor(index) }">
              <span class="scene-number">{{ sequence.scene.scene_nr }}</span>
              <span class="scene-type">{{ sequence.scene.location_type }}/{{ sequence.scene.time_of_day }}</span>
              <span class="scene-location">{{ sequence.scene.location }}</span>
              <span class="scene-time">{{ formatTime(sequence.sequence_start) }} - {{ formatTime(sequence.sequence_end) }}</span>
            </div>
            <div class="sequence-body">
              <p>{{ sequence.scene.synopsis }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Message when no project is selected or no schedule is found -->
    <div v-else class="no-schedule-message">
      <p v-if="selectedProjectId">No schedule found for this project.</p>
      <p v-else>Please select a project to view the schedule.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: [],
      selectedProjectId: "",
      schedule: [],
      scheduleGroupedByDay: [],
      daylightTime: "06:08 - 16:40", // Replace or calculate dynamically if needed
    };
  },
  async created() {
    await this.fetchProjects();
  },
  methods: {
    async fetchProjects() {
      try {
        const response = await fetch("https://api.fastwrap.io/projects", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }

        const data = await response.json();
        this.projects = data;
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    },
    async fetchSchedule() {
      if (!this.selectedProjectId) return;

      try {
        const response = await fetch(
          `https://api.fastwrap.io/projects/${this.selectedProjectId}/retrieve-schedule`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch schedule");
        }

        const data = await response.json();
        this.schedule = data.map((item) => ({
          ...item,
          scene: {
            scene_nr: item.scene.scene_nr,
            location_type: item.scene.location_type,
            time_of_day: item.scene.time_of_day,
            location: item.scene.location,
            synopsis: item.scene.synopsis,
          },
        }));

        // Group schedule by day
        this.scheduleGroupedByDay = this.groupByDay(this.schedule);
      } catch (error) {
        console.error("Error fetching schedule:", error);
      }
    },
    groupByDay(schedule) {
      const grouped = schedule.reduce((acc, item) => {
        const day = item.sequence_day;
        if (!acc[day]) {
          acc[day] = {
            dayNumber: day,
            sequences: [],
          };
        }
        acc[day].sequences.push(item);
        return acc;
      }, {});

      return Object.values(grouped).sort((a, b) => a.dayNumber - b.dayNumber);
    },
    formatTime(dateTime) {
      const date = new Date(dateTime);
      return date.toISOString().substr(11, 5); // Extracts time in HH:MM format
    },
    getBackgroundColor(index) {
      return index % 2 === 0 ? "#FFF4C1" : "#FFFFFF"; // Alternate background colors for rows
    },
  },
};
</script>

<style scoped>
.schedule-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 10px;
}

.project-selector {
  margin-bottom: 20px;
}

.project-selector select {
  padding: 5px;
  border-radius: 5px;
}

.schedule-header {
  display: flex;
  justify-content: space-between;
  background-color: #444;
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
}

.schedule-list {
  margin-top: 10px;
}

.sequence {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}

.sequence-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
}

.scene-number {
  font-weight: bold;
}

.sequence-body {
  padding: 10px;
  background-color: #f9f9f9;
}

.no-schedule-message {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #666;
}
</style>
